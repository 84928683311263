.App {
}

/* MOBILE */
@media only screen and (max-width: 599px) {
  body {
    padding: 10px;
    background-image: url("./backgrounds/back.png");
    background-size: 100% 500px;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {
body {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("./backgrounds/back.png");
  background-size: 100% 2000px;
  }
}

/* ============ FORMATTING ============ */

.row::after {
    content: "";
    clear: both;
    display: table;
}

.row {
  display: flex;
}

.stylized {
  font-family: 'Stylish', sans-serif;
}

.centered {
  text-align: center;
}


/* ============ COLORED BOXES ============ */

.evergreenBox {
  background-color: #10432A;
}

.mintBox {
  background-color: #306D6B;
}

.oliveBox {
  background-color: #5A7E41;
}

.robinBox {
  background-color: #6EAFE5;
}

.cornflowerBox {
  background-color: #9CB7DD;
}

.eggplantBox {
  background-color: #452989;
}

.lilacBox {
  background-color: #817EBB;
}

.amethystBox {
  background-color: #B1ADD0;
}

.mauveBox {
  background-color: #83326C;
}

.roseBox {
  background-color: #B9767D;
}

.pinkBox {
  background-color: #C3A4BC;
}

.redBox {
  background-color: #900000;
}

.goldBox {
  background-color: #D29000;
}

.blackBox {
  background-color: black;
}

/* ============ TEXT ACCENT COLORS ============ */

.evergreenText {
  color: #10432A;
  font-family: 'Stylish', sans-serif;
}

.mintText {
  color: #306D6B;
  font-family: 'Stylish', sans-serif;
}

.oliveText {
  color: #5A7E41;
  font-family: 'Stylish', sans-serif;
}

.robinText {
  color: #6EAFE5;
  font-family: 'Stylish', sans-serif;
}

.cornflowerText {
  color: #9CB7DD;
  font-family: 'Stylish', sans-serif;
}

.eggplantText {
  color: #452989;
  font-family: 'Stylish', sans-serif;
}

.lilacText {
  color: #817EBB;
  font-family: 'Stylish', sans-serif;
}

.amethystText {
  color: #B1ADD0;
  font-family: 'Stylish', sans-serif;
}

.mauveText {
  color: #83326C;
  font-family: 'Stylish', sans-serif;
}

.roseText {
  color: #B9767D;
  font-family: 'Stylish', sans-serif;
}

.pinkText {
  color: #C3A4BC;
  font-family: 'Stylish', sans-serif;
}

.redText {
  color: #900000;
  font-family: 'Stylish', sans-serif;
}

.goldText {
  color: #D29000;
  font-family: 'Stylish', sans-serif;
}

.whiteText {
  color: white;
  font-family: 'Stylish', sans-serif;
}

.blackText {
  color: black;
  font-family: 'Stylish', sans-serif;
}

/* ============ HEADER ============ */

/* MOBILE */
@media only screen and (max-width: 600px) {
  .header {
    width: 100%;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {
  .header {
    width: 1000px;
    margin-top: 60px;
  }
}

/* ============ HOME ============ */

/* MOBILE */
.smallFont {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Stylish', sans-serif;
  padding: 2vh 2vh 2vh 2vh;
  margin-top: -5px;
  background-color: white;
}

.homeBox {
  padding: 2vh 2vh 2vh 2vh;
  font-size: 22px;
  width: 50%;
  text-align: center;
}

.chapBox {
  padding: 2vh 2vh 2vh 2vh;
  font-size: 22px;
  font-family: 'Stylish', sans-serif;
}

.mobileRow {
  display: flex;
  font-family: 'Stylish', sans-serif;
}

.mobileRow::after {
    content: "";
    clear: both;
    display: table;
}

.lineMobile {
  height: 5px;
  background-color: black;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* DESKTOP */
.home {
  margin-top: 10px;
}

.gridContainer {
  padding: 5px 5px 0px 5px;
  margin-left: -4px;
}

.gridRectTop {
  width: 225.3px;
  height: 105px;
  padding: 10px;
}

.gridRectTop h1 {
  font-family: 'Stylish', sans-serif;
  font-size: 40px;
  margin-top: 0px;
  padding: 0px 5px;
  margin-left: 10px;
  text-align: center;
}

.gridRectTop h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  padding: 0px 5px 5px 5px;
  margin-top: -20px;
  margin-left: 10px;
  text-align: center;
}

.line {
  height: 5px;
  background-color: black;
  width: 100%;
  margin-top: 5px;
}

.gridPhoto {
  width: 150px;
  height: 115px;
  margin-left: 0px;
}

.gridRectHalf {
  width: 320.5px;
  height: 95px;
  padding: 10px;
}

.gridRectHalf h1 {
  font-family: 'Stylish', sans-serif;
  font-size: 40px;
  margin-top: 0px;
  padding: 0px 5px;
  margin-left: 10px;
}

.gridRectHalf h2 {
  font-family: 'Stylish', sans-serif;
  font-size: 25px;
  padding: 0px 5px 5px 5px;
  margin-top: -20px;
  margin-left: 10px;
}



/* ============ IMAGES & VIDEO ============ */

.chapImg {
  width: 100%;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .chapImgContainer {
    padding: 10px 10px;
    margin-top: 20px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .chapImgBorder {
      padding: 10px 10px 2px 10px;
  }

  .trailer {
    text-align: center;
    padding: 10px 0px;
  }

  .video {
    width: 320px;
    height: 200px;
  }

  .form {
    width: 320px;
    height: 550px;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {
  .chapImgContainer {
    padding: 10px 200px;
    margin-top: 20px;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  .chapImgBorder {
      padding: 20px 20px 13px 20px;
  }

  .trailer {
    text-align: center;
    padding: 20px;
  }

  .video {
    width: 800px;
    height: 450px;
  }

  .form {
    width: 800px;
    height: 450px;
  }

}

/* ============ CHAPTER STUFF ============ */

/* MOBILE */
@media only screen and (max-width: 600px) {

  .chapterBox {
    padding: 2vh 0vh 0vh 0vh;
    font-size: 16px;
    font-family: 'Stylish', sans-serif;
  }

  .chapterBox h1 {
    font-family: 'Stylish', sans-serif;
    margin-top: -5px;
    margin-left: 10px;
  }

  .chapterBody h2 {
    text-align: center;
    font-family: 'Stylish', sans-serif;
  }

  .chapterBody {
    font-family: 'Source Sans Pro', sans-serif;
    padding: 5px 15px 5px 15px;
    line-height: 30px;
    font-size: 18px;
    min-height: 400px;
    background-color: white;
    margin-top: -15px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .chapterBody h4 {
    font-size: 12px;
  }

  .pgNum {
    text-align: right;
    font-family: 'Stylish', sans-serif;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .chapFW {
    font-family: 'Stylish', sans-serif;
    font-size: 30px;
    border: 1px solid black;
    padding: 5px;
    margin-right: 5px;
  }

}

/* DESKTOP */
@media only screen and (min-width: 600px) {

  .chapterBox {
    width: 999px;
    height: 30px;
    padding: 10px 0px;
    margin-top: 3px;
    margin-left: 1px;
  }

  .chapterBox h1 {
    font-family: 'Stylish', sans-serif;
    margin-top: -5px;
    margin-left: 10px;
  }

  .chapterBody {
    padding: 50px 70px 50px 70px;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 35px;
    min-height: 45vh;
  }

  .chapterBody h2 {
    text-align: center;
    font-family: 'Stylish', sans-serif;
    font-size: 30px;
  }

  .chapterBody h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
  }

  .chapterBody h4 {
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .chapterBody p {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .pgNum {
    text-align: right;
    font-family: 'Stylish', sans-serif;
    font-size: 40px;
    margin-top: -25px;
  }

  .chapFW {
    font-family: 'Stylish', sans-serif;
    font-size: 50px;
    border: 1px solid black;
    padding: 5px;
    margin-right: 5px;
  }
}



/* ============ DIARY ============ */


/* MOBILE */
@media only screen and (max-width: 600px) {
  .diaryBox1 {
      padding: 10px 10px;
      margin-top: 20px;
  }

  .diaryBox2 {
      padding: 10px;
      background-color: black;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {
  .diaryBox1 {
      padding: 10px 150px;
      margin-top: 30px;
  }

  .diaryBox2 {
      padding: 20px;
      background-color: black;
  }
}

.diaryBox3 {
    background-image: url("./backgrounds/notebook_paper.jpg");
    padding: 10px;
}

.diary {
    font-family: 'Homemade Apple', cursive;
    font-size: 16px;
    padding: 0px 0px 0px 45px;
}

/* marquee scroll text*/
.diaryScrollBox {
    font-family: 'Homemade Apple', cursive;
    font-size: 16px;
    padding: 0px 0px 0px 45px;
    padding: 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-x: auto;
    margin-bottom: 10px;
}


/* ============ INDEX CARD ============ */

.indexCardContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.indexCard h2 {
  font-family: 'Caveat';
}

.indexCard h3 {
  font-family: 'Caveat';
}

/* MOBILE */
@media only screen and (max-width: 600px) {

  .indexCard {
    background-image: url("./backgrounds/index_card.jpg");
    background-size: 500px 500px;
    padding: 0px 30px 5px 30px;
    background-repeat: repeat-y;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {

  .indexCard {
    background-image: url("./backgrounds/index_card.jpg");
    background-size: 1000px 450px;
    padding: 0px 30px 5px 30px;
  }
}

.editorsNote {
  font-family: 'Permanent Marker';
  font-size: 16px;
  text-align: center;
  color: red;
}

.editorsNote2 {
  font-family: 'Permanent Marker';
  font-size: 16px;
  padding: 1%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid black;
  color: red;
}

/* ============ SOUNDCLOUD ============ */

.soundcloudContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .soundcloud {
    width: 200px;
    height: 200px;
  }
}

/* DESKTOP */
@media only screen and (min-width: 600px) {
  .soundcloud {
    width: 400px;
    height: 200px;
  }
}

/* ============ FOOTER ============ */
@media only screen and (max-width: 600px) {
  footer {
    background-color: #9CB7DD;
    width: 100%;
    height: 30px;
    margin-top: 5px;
    padding: 5px 0px;
    position: relative;
    left: 0px;
    bottom: 0px;
    right: 0px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 600px) {
  footer {
    background-color: #9CB7DD;
    width: 1000px;
    height: 30px;
    margin-top: 5px;
    padding: 10px 0px;
    position: relative;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
}

footer h3 {
  font-family: 'Stylish', sans-serif;
  text-align: center;
  margin-top: 5px;
}
